var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    [
      _c("h6", [_vm._v(_vm._s(_vm.FormMSG(11, "Dates")))]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticStyle: { "padding-top": "15px" } },
            [
              _c("b-form-select", {
                staticClass: "mt-3",
                attrs: {
                  size: "sm",
                  disabled: _vm.disabled,
                  options: _vm.datesOptionsMap,
                },
                model: {
                  value: _vm.dateFieldsPayload.timeType,
                  callback: function ($$v) {
                    _vm.$set(_vm.dateFieldsPayload, "timeType", $$v)
                  },
                  expression: "dateFieldsPayload.timeType",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(987789, "Transportation type"),
                    "label-for": "optionsForTransportMean",
                  },
                },
                [
                  _c("b-form-select", {
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: {
                      id: "optionsForTransportMean",
                      disabled: _vm.disabled,
                      options: _vm.optionsForTransportMean,
                    },
                    model: {
                      value: _vm.dateFieldsPayload.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.dateFieldsPayload, "type", $$v)
                      },
                      expression: "dateFieldsPayload.type",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "8" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("label", { attrs: { for: "transport-time" } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(434, "Choose a time")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                      _c("b-form-input", {
                        attrs: {
                          id: "transport-time",
                          type: "time",
                          disabled: _vm.disabled,
                        },
                        model: {
                          value: _vm.dateFieldsPayload.time,
                          callback: function ($$v) {
                            _vm.$set(_vm.dateFieldsPayload, "time", $$v)
                          },
                          expression: "dateFieldsPayload.time",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c("label", { attrs: { for: "transport-date" } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(1, "Choose a date")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                      _c("b-form-datepicker", {
                        attrs: {
                          id: "transport-date",
                          disabled: _vm.disabled,
                          locale: _vm.currentLang,
                        },
                        model: {
                          value: _vm.dateFieldsPayload.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.dateFieldsPayload, "date", $$v)
                          },
                          expression: "dateFieldsPayload.date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "push_t_20" },
        [
          _c(
            "b-col",
            [
              _c("h6", [_vm._v(_vm._s(_vm.FormMSG(678786, "Transport mean")))]),
              _c("b-form-input", {
                attrs: {
                  id: "transport-mean--input",
                  "aria-describedby": "transport-mean--input-help",
                  placeholder: _vm.FormMSG(978879, "Ex: 'Mike's car'"),
                },
                model: {
                  value: _vm.transportMean,
                  callback: function ($$v) {
                    _vm.transportMean = $$v
                  },
                  expression: "transportMean",
                },
              }),
              _c(
                "b-form-text",
                { attrs: { id: "transport-mean--input-help" } },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(
                        _vm.FormMSG(67678, "transport additional information")
                      ) +
                      "\n\t\t\t"
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "push_t_60" },
        [
          _c("b-col", [
            _c(
              "div",
              { staticClass: "content_sb_middle_start" },
              [
                _c(
                  "b-form-checkbox",
                  {
                    attrs: {
                      id: "checkbox-1",
                      disabled: _vm.disabled,
                      name: "checkbox-1",
                    },
                    model: {
                      value: _vm.restrictionWaysPayload.avoidHighways,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.restrictionWaysPayload,
                          "avoidHighways",
                          $$v
                        )
                      },
                      expression: "restrictionWaysPayload.avoidHighways",
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(675, "Avoid highways")) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
                _c(
                  "b-form-checkbox",
                  {
                    staticStyle: { "margin-left": "20px" },
                    attrs: {
                      id: "checkbox-2",
                      disabled: _vm.disabled,
                      name: "checkbox-2",
                    },
                    model: {
                      value: _vm.restrictionWaysPayload.avoidTolls,
                      callback: function ($$v) {
                        _vm.$set(_vm.restrictionWaysPayload, "avoidTolls", $$v)
                      },
                      expression: "restrictionWaysPayload.avoidTolls",
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(16656, "Avoid tolls")) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }