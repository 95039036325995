var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { class: { "card-alert-danger": _vm.isLate } },
    [
      _vm.loading
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-alert",
                    {
                      staticClass: "full",
                      attrs: { show: "", variant: "default" },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.FormMSG(234234, "Estimation loading..."))
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.estimationStatus === "error"
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-alert",
                            {
                              staticClass: "full",
                              attrs: { show: "", variant: "danger" },
                            },
                            [
                              _vm.durationEstimation.message ===
                              "INVALID_REQUEST"
                                ? _c("span", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(
                                            567567,
                                            "Can't estimate a transport in past"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t"
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.FormMSG(
                                          89890,
                                          "Something went wrong"
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.estimationStatus === "success" &&
              _vm.$has(_vm.durationEstimation.estimation)
                ? _c(
                    "div",
                    [
                      _vm.isDiffDurationCalcul
                        ? _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-alert",
                                    {
                                      staticClass: "full",
                                      attrs: { show: "", variant: "warning" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.FormMSG(
                                              678678678,
                                              "Please note that this estimated travel time is not based on your selected mode of transport but on a car trip"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-alert",
                                {
                                  staticClass: "full",
                                  attrs: {
                                    show: "",
                                    variant: _vm.rendAlertVariant,
                                  },
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _vm.$has(_vm.departureTime)
                                        ? _c("b-col", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(1242, "Depart")
                                                ) + ": "
                                              ),
                                            ]),
                                            _c("span", [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatDateTime")(
                                                      _vm.departureTime
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]),
                                          ])
                                        : _vm._e(),
                                      _vm.$has(_vm.arrivalTime)
                                        ? _c("b-col", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(12842, "Arrival")
                                                ) + ": "
                                              ),
                                            ]),
                                            _c("span", [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatDateTime")(
                                                      _vm.arrivalTime
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._l(
                        _vm.durationEstimation.estimation.rows,
                        function (row, i) {
                          return _c(
                            "b-row",
                            { key: i },
                            [
                              row.elements[0].status === "ZERO_RESULTS"
                                ? _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-alert",
                                        {
                                          staticClass: "full",
                                          attrs: {
                                            show: "",
                                            variant: "danger",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  456456,
                                                  "There's no results for this trip"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-row",
                                        _vm._l(
                                          _vm.rendElementsMap(row.elements[0]),
                                          function (item, j) {
                                            return _c(
                                              "b-col",
                                              { key: j },
                                              [
                                                _c("b-card", [
                                                  _c("h5", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("capitalize")(
                                                          item.title
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _c("br"),
                                                  _c("span", [
                                                    _c("b", [
                                                      _vm._v(_vm._s(item.text)),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                      _vm.$has(_vm.kgCoTwo)
                                        ? _c(
                                            "b-row",
                                            [
                                              _c("b-col", [
                                                _c(
                                                  "span",
                                                  {
                                                    class:
                                                      _vm.rendKgCoTwoVariant,
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          47689,
                                                          "kgCoTwo"
                                                        )
                                                      ) + ": "
                                                    ),
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(_vm.kgCoTwo)
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }