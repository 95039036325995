<template>
	<b-card>
		<h6>{{ FormMSG(11, 'Dates') }}</h6>
		<b-row>
			<b-col style="padding-top: 15px">
				<b-form-select v-model="dateFieldsPayload.timeType" size="sm" class="mt-3" :disabled="disabled" :options="datesOptionsMap" />
			</b-col>
			<b-col>
				<b-form-group :label="FormMSG(987789, 'Transportation type')" label-for="optionsForTransportMean">
					<b-form-select
						id="optionsForTransportMean"
						v-model="dateFieldsPayload.type"
						style="margin-bottom: 20px"
						:disabled="disabled"
						:options="optionsForTransportMean"
					/>
				</b-form-group>
			</b-col>
			<b-col cols="8">
				<b-row>
					<b-col>
						<label for="transport-time">
							{{ FormMSG(434, 'Choose a time') }}
						</label>
						<b-form-input v-model="dateFieldsPayload.time" id="transport-time" type="time" :disabled="disabled" />
					</b-col>
					<b-col>
						<label for="transport-date">
							{{ FormMSG(1, 'Choose a date') }}
						</label>
						<!-- TODO: set default date to today -->
						<b-form-datepicker id="transport-date" :disabled="disabled" v-model="dateFieldsPayload.date" :locale="currentLang" />
					</b-col>
				</b-row>
			</b-col> </b-row
		><b-row class="push_t_20">
			<b-col>
				<h6>{{ FormMSG(678786, 'Transport mean') }}</h6>
				<b-form-input
					v-model="transportMean"
					id="transport-mean--input"
					aria-describedby="transport-mean--input-help"
					:placeholder="FormMSG(978879, 'Ex: \'Mike\'s car\'')"
				/>
				<b-form-text id="transport-mean--input-help">
					{{ FormMSG(67678, 'transport additional information') }}
				</b-form-text>
			</b-col>
		</b-row>
		<b-row class="push_t_60">
			<b-col>
				<div class="content_sb_middle_start">
					<b-form-checkbox id="checkbox-1" v-model="restrictionWaysPayload.avoidHighways" :disabled="disabled" name="checkbox-1">
						{{ FormMSG(675, 'Avoid highways') }}
					</b-form-checkbox>
					<b-form-checkbox
						id="checkbox-2"
						style="margin-left: 20px"
						v-model="restrictionWaysPayload.avoidTolls"
						:disabled="disabled"
						name="checkbox-2"
					>
						{{ FormMSG(16656, 'Avoid tolls') }}
					</b-form-checkbox>
				</div>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
import moment from 'moment';
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';

const now = new Date();
const dateFieldsDefault = {
	timeType: 'departure',
	type: '0', // car
	date: moment(now).format('YYYY-MM-DD'),
	time: moment(now).format('HH:mm:ss')
};
const restrictionWaysDefaultPayload = {
	avoidHighways: false,
	avoidTolls: false
};

export default {
	name: 'DateTransportModuleModal',
	mixins: [languageMessages, GlobalMixin],
	props: {
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		editData: {
			type: Object,
			required: false,
			default: null
		}
	},
	data() {
		return {
			transportMean: '',
			defaultDateTime: now,
			noDateDefault: '0001-01-01T00:00:00Z',
			dateFieldsPayload: Object.assign({}, dateFieldsDefault),
			restrictionWaysPayload: Object.assign({}, restrictionWaysDefaultPayload)
		};
	},
	computed: {
		/**
		 * @return {Array}
		 */
		datesOptionsMap() {
			const t = this.FormMSG;
			return [
				{ value: 'departure', text: t(131, 'Departure at') },
				{ value: 'arrival', text: t(121, 'Arrival at') }
			];
		},

		/**
		 * @return {Array}
		 */
		optionsForTransportMean() {
			let result = this.FormMenu(905); // 1124 is without planes

			result = result.sort(function compare(a, b) {
				if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
				if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
				return 0;
			});

			return result;
		}
	},
	watch: {
		dateFieldsPayload: {
			/**
			 * @param {Object} payload
			 */
			handler(payload) {
				this.$emit('change', payload);
			},
			deep: true
		},

		restrictionWaysPayload: {
			/**
			 * @param {Object} payload
			 */
			handler(payload) {
				this.$emit('restriction-ways-change', payload);
			},
			deep: true
		},

		transportMean(payload) {
			this.handleTransportMean(payload);
		}
	},
	created() {
		this.initBaseData();
		this.transportMean = this.editData.transportMean;
	},
	methods: {
		initBaseData() {
			if (this.editData.departureTime === this.noDateDefault) return;

			const savedDateField = this.editData.depArrStd === 1 ? 'arrivalTime' : 'departureTime';

			const saved = new Date(this.editData[savedDateField]);
			this.defaultDateTime = saved;

			this.dateFieldsPayload = {
				...this.dateFieldsPayload,
				...this.editData,
				timeType: this.setTimeType(this.editData.depArrStd),
				date: moment(saved).format('YYYY-MM-DD'),
				time: moment(saved).format('HH:mm:ss'),
				type: this.editData.type
			};
		},

		/**
		 * @param {Number} timeType
		 * @return {Number}
		 */
		setTimeType(timeType) {
			let res = 'departure';
			if (timeType === 1) res = 'arrival';
			return res;
		},

		/**
		 * @param {String} transportMean
		 * @return {Emitter}
		 */
		handleTransportMean(transportMean) {
			this.$emit('mean-change', transportMean);
		}
	}
};
</script>
