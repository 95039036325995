var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _vm.editData
            ? _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c("b-card-header", [
                    _c("h4", [_vm._v(_vm._s(_vm.rendPageTitle))]),
                  ]),
                  _c(
                    "b-card-body",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12", xl: "12" } },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "12", lg: "9" } },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { sm: "12" } },
                                            [
                                              _vm.editData
                                                ? _c("b-card", [
                                                    _c("h6", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            12131,
                                                            "From To Address"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "selectors-holder",
                                                      },
                                                      [
                                                        _c("AddressMaker", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.isEditionDisabled,
                                                            type: "from",
                                                            "edit-data":
                                                              _vm.editData,
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.handleFromAddressChange,
                                                          },
                                                        }),
                                                        _c("AddressMaker", {
                                                          staticStyle: {
                                                            "margin-top":
                                                              "20px",
                                                          },
                                                          attrs: {
                                                            disabled:
                                                              _vm.isEditionDisabled,
                                                            type: "to",
                                                            "edit-data":
                                                              _vm.editData,
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.handleToAddressChange,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.$has(_vm.editData)
                                        ? _c(
                                            "b-row",
                                            [
                                              _vm.hasDirection
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { sm: "12" } },
                                                    [
                                                      _c("DateMakerCard", {
                                                        attrs: {
                                                          disabled:
                                                            _vm.isEditionDisabled,
                                                          "edit-data":
                                                            _vm.editData,
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.handleDateChange,
                                                          "restriction-ways-change":
                                                            _vm.handleRestrictionWaysChange,
                                                          "mean-change":
                                                            _vm.handleTransportMean,
                                                        },
                                                      }),
                                                      _c(
                                                        "ShowTransportEstimationResult",
                                                        {
                                                          attrs: {
                                                            loading:
                                                              _vm.isDurationEstimationLoading,
                                                            "is-diff-duration-calcul":
                                                              _vm.isDurationCalculatedBasedOnCarButNotIsDeferentMean,
                                                            "departure-time":
                                                              _vm.editData
                                                                .departureTime,
                                                            "arrival-time":
                                                              _vm.editData
                                                                .arrivalTime,
                                                            "kg-co-two":
                                                              _vm.editData
                                                                .kgCoTwo,
                                                            "duration-estimation":
                                                              _vm.durationEstimation,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c("b-col", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            786,
                                                            "No direction"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { sm: "12" } },
                                            [
                                              _c("DriverAndPassengerCard", {
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditionDisabled,
                                                  transport: _vm.editData,
                                                },
                                                on: {
                                                  "driver-change":
                                                    _vm.handleDriverChange,
                                                  "car-change":
                                                    _vm.handleCarChange,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { sm: "12" } },
                                            [
                                              _c("VehiculesCard", {
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditionDisabled,
                                                  "edit-data": _vm.editData,
                                                },
                                                on: {
                                                  change: _vm.handleCarChange,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { sm: "12" } },
                                            [
                                              _c("b-form-textarea", {
                                                attrs: {
                                                  id: "transport-extraInfo",
                                                  disabled:
                                                    _vm.isEditionDisabled,
                                                  placeholder: _vm.FormMSG(
                                                    342342,
                                                    "Transport's extra info..."
                                                  ),
                                                  rows: "3",
                                                  required: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.transportPaylaod
                                                      .extraInfo,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.transportPaylaod,
                                                      "extraInfo",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "transportPaylaod.extraInfo",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "12", lg: "3" } },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "full-width-on-mobile",
                                                      attrs: {
                                                        variant: "success",
                                                        size: "lg",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.goToTransports,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              21,
                                                              "Back to transports"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                      _c("i", {
                                                        staticClass:
                                                          "icon-action-undo",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "full-width-on-mobile",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "lg",
                                                        disabled:
                                                          _vm.disableSave ||
                                                          _vm.isEditionDisabled,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.updtTransport,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              2123,
                                                              "Save modifications"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }