<template>
	<b-card :class="{ 'card-alert-danger': isLate }">
		<b-row v-if="loading">
			<b-col>
				<b-alert show class="full" variant="default">
					<span>{{ FormMSG(234234, 'Estimation loading...') }}</span>
				</b-alert>
			</b-col>
		</b-row>

		<div v-else>
			<b-row v-if="estimationStatus === 'error'">
				<b-col>
					<b-alert show class="full" variant="danger">
						<span v-if="durationEstimation.message === 'INVALID_REQUEST'">
							{{ FormMSG(567567, "Can't estimate a transport in past") }}
						</span>
						<span v-else>{{ FormMSG(89890, 'Something went wrong') }}</span>
					</b-alert>
				</b-col>
			</b-row>

			<div v-if="estimationStatus === 'success' && $has(durationEstimation.estimation)">
				<b-row v-if="isDiffDurationCalcul">
					<b-col>
						<b-alert show class="full" variant="warning">
							<span>{{
								FormMSG(
									678678678,
									'Please note that this estimated travel time is not based on your selected mode of transport but on a car trip'
								)
							}}</span>
						</b-alert>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-alert class="full" show :variant="rendAlertVariant">
							<b-row>
								<b-col v-if="$has(departureTime)">
									<span>{{ FormMSG(1242, 'Depart') }}: </span>
									<span>
										<b>{{ departureTime | formatDateTime }}</b>
									</span>
								</b-col>

								<b-col v-if="$has(arrivalTime)">
									<span>{{ FormMSG(12842, 'Arrival') }}: </span>
									<span>
										<b>{{ arrivalTime | formatDateTime }}</b>
									</span>
								</b-col>
							</b-row>
						</b-alert>
					</b-col>
				</b-row>

				<b-row v-for="(row, i) in durationEstimation.estimation.rows" :key="i">
					<b-col v-if="row.elements[0].status === 'ZERO_RESULTS'">
						<b-alert show class="full" variant="danger">
							<span>{{ FormMSG(456456, "There's no results for this trip") }}</span>
						</b-alert>
					</b-col>
					<b-col v-else>
						<b-row>
							<b-col v-for="(item, j) in rendElementsMap(row.elements[0])" :key="j">
								<b-card>
									<h5>{{ item.title | capitalize }}</h5>
									<br />
									<span>
										<b>{{ item.text }}</b>
									</span>
								</b-card>
							</b-col>
						</b-row>
						<b-row v-if="$has(kgCoTwo)">
							<b-col>
								<span :class="rendKgCoTwoVariant"
									>{{ FormMSG(47689, 'kgCoTwo') }}: <b>{{ kgCoTwo }}</b></span
								>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</div>
		</div>
	</b-card>
</template>

<script>
import moment from 'moment';
import mapProps from '@/shared/vuePropsMapper';
import { isNil } from '@/shared/utils';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'ShowTransportEstimationResultComponent',
	mixins: [languageMessages],
	props: {
		...mapProps(['departureTime', 'arrivalTime'], {
			type: [Date, String],
			required: false,
			default: null
		}),
		...mapProps(['durationEstimation', 'estimatedArrival'], {
			type: Object,
			required: false,
			default: null
		}),
		...mapProps(['loading', 'isDiffDurationCalcul'], {
			type: Boolean,
			required: false,
			default: false
		}),
		kgCoTwo: {
			type: [Number, String],
			required: false,
			default: null
		}
	},
	computed: {
		/**
		 * @return {Boolean}
		 */
		isFutur() {
			if (isNil(this.departureTime)) return;
			return this.inFuture(this.departureTime);
		},

		/**
		 * @return {Boolean}
		 */
		isLate() {
			if (isNil(this.departureTime)) return false;
			return new Date() > new Date(this.departureTime);
		},

		/**
		 * @return {Boolean}
		 */
		isSoon() {
			if (isNil(this.departureTime)) return false;
			return this.inSoonTime(this.departureTime);
		},

		/**
		 * @return {String}
		 */
		rendAlertVariant() {
			if (this.isLate) return 'danger';
			if (this.isSoon) return 'warning';
			return 'default';
		},

		/**
		 * @return {String}
		 */
		rendKgCoTwoVariant() {
			const kg = this.kgCoTwo;
			if (kg > 10) return 'text-danger';
			return kg < 1 ? 'text-success' : 'text-info';
		},

		/**
		 * @return {String}
		 */
		estimationStatus() {
			if (isNil(this.durationEstimation)) return 'loading';
			return this.durationEstimation.status;
		}
	},
	methods: {
		/**
		 * @param {Array} elements
		 * @return {Array}
		 */
		rendElementsMap(elements) {
			const res = [];
			Object.keys(elements).forEach((k, i) => {
				if (elements[k] === 'OK') return;
				const title = k === 'duration_in_traffic' ? 'Duration in traffic' : k;
				res.push({
					text: elements[k].text,
					title: this.FormMSG(234 + i, title)
				});
			});
			return res;
		},

		inFuture(date) {
			if (isNil(date)) return;
			const _d = new Date(date);
			return _d.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0);
		},

		inSoonTime(date) {
			const _d = moment(new Date(date));
			const now = moment(new Date());
			const duration = moment.duration(_d.diff(now));
			const hours = duration.asHours();
			return hours <= 1;
		}
	}
};
</script>
